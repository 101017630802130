<script setup>
import { ref, onMounted, onBeforeMount, watch } from 'vue'
import { SimpleBar } from "simplebar-vue3";
import store from "@/state/store";
import i18n from "../i18n";
import Swal from "sweetalert2";
import axios from "axios";
import { useRouter } from 'vue-router';

import TimeAgo from 'javascript-time-ago'
import tr from 'javascript-time-ago/locale/tr'

TimeAgo.addLocale(tr)

const timeAgo = new TimeAgo('tr')

const router = useRouter();

const languages = [
  {
    flag: require("@/assets/images/flags/tr.svg"),
    language: "tr",
    title: "Türkçe",
  },
  {
    flag: require("@/assets/images/flags/us.svg"),
    language: "en",
    title: "English",
  },
]

const lan = ref(i18n.locale);
const text = ref(null)
const flag = ref(null)
const user = ref(store.getters['userAuth/getUserData'])
// const selectedDraft = ref(store.getters['user/getSelectedDraftDoc'])
// const aktifBelgeler = ref(store.getters['user/getAktifBelgeler'])
// const draftDocs = ref(store.getters['user/getDraftDocs'])

const logOut = function(msg){
  Swal.fire({
      title: "Oturum Sonlandırıldı!",
      html: msg,
      icon: "error",
      timer: 1500,
      confirmButtonText: "Tamam",
    });
    router.push({'name' : 'guvenli-cikis'})
}

onBeforeMount(async () => {
  await axios.post(process.env.VUE_APP_B2B_API_URL+'auth/check').then((data) => {
    if(!data.data.status){ logOut('Oturum doğrulanamadı.') }
  }).catch((error) => { logOut(error.response.data.message) })
})

onMounted(() => {

  // store.dispatch("user/getBelge").then(() => {
  //   aktifBelgeler.value = store.getters["user/getAktifBelgeler"]
  // });

  store.dispatch("user/getDraftDocs").then(() => {})

  document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
          ? pageTopbar.classList.add("topbar-shadow")
          : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", toggleHamburgerMenu());

    isCustomDropdown();
    toggleHamburgerMenu();
})

function selectDraftDoc(docnum){
  console.log(docnum)
  store.dispatch(
    "user/selectDraftDoc",
    { docnum: docnum },
  ).then(() => {
    // selectedDraft.value = store.getters['user/getSelectedDraftDoc']
  })
}
function removeCartProduct(index) {
  store.dispatch(
    "cart/removeCartProduct",
    { index: index },
    {
      namespaced: true,
    }
  );
  Swal.fire({
    title: "Sepetten Kaldırıldı!",
    html: "Ürün başarıyla sepeten başarıyla kaldırıldı.",
    icon: "success",
    confirmButtonText: "Tamam",
  });
}
function isCustomDropdown() {

  var searchOptions = document.getElementById("search-close-options");
  var dropdown = document.getElementById("search-dropdown");
  var searchInput = document.getElementById("search-options");

  searchInput.addEventListener("focus", () => {
    var inputLength = searchInput.value.length;
    if (inputLength > 0) {
      dropdown.classList.add("show");
      searchOptions.classList.remove("d-none");
    } else {
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    }
  });

  searchInput.addEventListener("keyup", () => {
    var inputLength = searchInput.value.length;
    if (inputLength > 0) {
      dropdown.classList.add("show");
      searchOptions.classList.remove("d-none");
    } else {
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    }
  });

  searchOptions.addEventListener("click", () => {
    searchInput.value = "";
    dropdown.classList.remove("show");
    searchOptions.classList.add("d-none");
  });

  document.body.addEventListener("click", (e) => {
    if (e.target.getAttribute("id") !== "search-options") {
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    }
  });
}
function toggleHamburgerMenu() {
  var windowSize = document.documentElement.clientWidth;

  if (windowSize > 767)
    document.querySelector(".hamburger-icon").classList.toggle("open");

  //For collapse horizontal menu
  if (
    document.documentElement.getAttribute("data-layout") === "horizontal"
  ) {
    document.body.classList.contains("menu")
      ? document.body.classList.remove("menu")
      : document.body.classList.add("menu");
  }

  //For collapse vertical menu
  if (document.documentElement.getAttribute("data-layout") === "vertical") {
    if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") == "sm"
        ? document.documentElement.setAttribute("data-sidebar-size", "")
        : document.documentElement.setAttribute("data-sidebar-size", "sm");
    } else if (windowSize > 1025) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") == "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm")
        : document.documentElement.setAttribute("data-sidebar-size", "lg");
    } else if (windowSize <= 767) {
      document.body.classList.add("vertical-sidebar-enable");
      document.documentElement.setAttribute("data-sidebar-size", "lg");
    }
  }

  //Two column menu
  if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
    document.body.classList.contains("twocolumn-panel")
      ? document.body.classList.remove("twocolumn-panel")
      : document.body.classList.add("twocolumn-panel");
  }
}

function setLanguage(nLocale, nCountry, nFlag) {
  lan.value = nLocale;
  text.value = nCountry;
  flag.value = nFlag;
  document.getElementById("header-lang-img").setAttribute("src", nFlag);
  i18n.global.locale = nLocale;
}
function toggleDarkMode() {
  if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
    document.documentElement.setAttribute("data-layout-mode", "light");
  } else {
    document.documentElement.setAttribute("data-layout-mode", "dark");
  }
}


watch(() => store.getters['cart/getCart'], function() {
  console.log('Sepet güncellendi.');
});
</script>

  <template>
    <header id="page-topbar">
      <div class="layout-width">
        <div class="navbar-header">
          <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box horizontal-logo">
              <router-link to="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img src="@/assets/images/logo-dark.png" alt="" height="33" />
                </span>
                <span class="logo-lg">
                  <img src="@/assets/images/logo-dark.png" alt="" height="33" />
                </span>
              </router-link>
  
              <router-link to="/" class="logo logo-light">
                <span class="logo-sm">
                  <img src="@/assets/images/logo-light.png" alt="" height="33" />
                </span>
                <span class="logo-lg">
                  <img src="@/assets/images/logo-light.png" alt="" height="33" />
                </span>
              </router-link>
            </div>
  
            <button
              type="button"
              class="
                btn btn-sm
                px-3
                fs-16
                header-item
                vertical-menu-btn
                topnav-hamburger
              "
              id="topnav-hamburger-icon"
              @click="toggleHamburgerMenu()"
            >
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
  
            <!-- App Search-->
            <form class="app-search d-none d-md-block">
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  autocomplete="off"
                  id="search-options"
                  value=""
                />
                <span class="mdi mdi-magnify search-widget-icon"></span>
                <span
                  class="
                    mdi mdi-close-circle
                    search-widget-icon search-widget-icon-close
                    d-none
                  "
                  id="search-close-options"
                ></span>
              </div>
              <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                <SimpleBar data-simplebar style="max-height: 320px">
                  <!-- item-->
                  <div class="dropdown-header">
                    <h6 class="text-overflow text-muted mb-0 text-uppercase">
                      Recent Searches
                    </h6>
                  </div>
  
                  <div class="dropdown-item bg-transparent text-wrap">
                    <router-link
                      to="/"
                      class="btn btn-soft-secondary btn-sm btn-rounded"
                      >how to setup <i class="mdi mdi-magnify ms-1"></i
                    ></router-link>
                    <router-link
                      to="/"
                      class="btn btn-soft-secondary btn-sm btn-rounded"
                      >buttons <i class="mdi mdi-magnify ms-1"></i
                    ></router-link>
                  </div>
                  <!-- item-->
                  <div class="dropdown-header mt-2">
                    <h6 class="text-overflow text-muted mb-1 text-uppercase">
                      Pages
                    </h6>
                  </div>
  
                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i
                      class="
                        ri-bubble-chart-line
                        align-middle
                        fs-18
                        text-muted
                        me-2
                      "
                    ></i>
                    <span>Analytics Dashboard</span>
                  </a>
  
                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i
                      class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"
                    ></i>
                    <span>Help Center</span>
                  </a>
  
                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i
                      class="
                        ri-user-settings-line
                        align-middle
                        fs-18
                        text-muted
                        me-2
                      "
                    ></i>
                    <span>My account settings</span>
                  </a>
  
                  <!-- item-->
                  <div class="dropdown-header mt-2">
                    <h6 class="text-overflow text-muted mb-2 text-uppercase">
                      Members
                    </h6>
                  </div>
  
                  <div class="notification-list">
                    <!-- item -->
                    <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                    >
                      <img
                        src="@/assets/images/users/avatar-2.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">Angela Bernier</h6>
                        <span class="fs-11 mb-0 text-muted">Manager</span>
                      </div>
                    </a>
                    <!-- item -->
                    <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                    >
                      <img
                        src="@/assets/images/users/avatar-3.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">David Grasso</h6>
                        <span class="fs-11 mb-0 text-muted">Web Designer</span>
                      </div>
                    </a>
                    <!-- item -->
                    <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                    >
                      <img
                        src="@/assets/images/users/avatar-5.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">Mike Bunch</h6>
                        <span class="fs-11 mb-0 text-muted">React Developer</span>
                      </div>
                    </a>
                  </div>
                </SimpleBar>
  
                <div class="text-center pt-3 pb-1">
                  <router-link
                    to="/"
                    class="btn btn-primary btn-sm"
                    >View All Results <i class="ri-arrow-right-line ms-1"></i
                  ></router-link>
                </div>
              </div>
            </form>
          </div>
  
          <div class="d-flex align-items-center">
            <div class="dropdown d-md-none topbar-head-dropdown header-item">
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-search fs-22"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form class="p-3">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <button class="btn btn-primary" type="submit">
                        <i class="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <div class="dropdown ms-1 topbar-head-dropdown header-item">
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  id="header-lang-img"
                  src="@/assets/images/flags/tr.svg"
                  alt="Header Language"
                  height="20"
                  class="rounded"
                />
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <a
                  href="javascript:void(0);"
                  v-for="(entry, i) in languages"
                  :key="`Lang${i}`"
                  :value="entry"
                  @click="setLanguage(entry.language, entry.title, entry.flag)"
                  :class="{ active: lan === entry.language }"
                  class="dropdown-item notify-item language py-2"
                  data-lang="tr"
                  title="English"
                >
                  <img
                    :src="entry.flag"
                    alt="user-image"
                    class="me-2 rounded"
                    height="18"
                  />
                  <span class="align-middle">{{ entry.title }}</span>
                </a>
              </div>
            </div>
  
            <div class="dropdown topbar-head-dropdown ms-1 header-item">
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-cart-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-shopping-bag fs-22"></i>
                <span
                  class="
                    position-absolute
                    topbar-badge
                    fs-10
                    translate-middle
                    badge
                    rounded-pill
                    bg-info
                  "
                  >{{ $store.getters["cart/getCart"].length
                  }}<span class="visually-hidden">unread messages</span></span
                >
              </button>
              <div
                class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0"
                aria-labelledby="page-header-cart-dropdown"
              >
                <div
                  class="
                    p-3
                    border-top-0 border-start-0 border-end-0 border-dashed border
                  "
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 fs-16 fw-semibold">{{ $t("Sepet") }}</h6>
                    </div>
                    <div class="col-auto">
                      <span class="badge badge-soft-warning fs-13">
                        {{ $store.getters["cart/getCart"].length }} {{ $t("Ürün") }}</span
                      >
                    </div>
                  </div>
                </div>
                <SimpleBar data-simplebar style="max-height: 300px">
                  <div class="p-2">
                    <div
                      class="d-block dropdown-item text-wrap px-3 py-2"
                      v-for="(product, index) in $store.getters['cart/getCart']"
                      :key="product.material"
                    >
                      <div class="d-flex align-items-center">
                        <img
                          :src="product.image"
                          class="me-3 rounded-circle avatar-sm p-2 bg-light"
                          alt="Product-Picture"
                        />
                        <div class="flex-1">
                          <h6 class="mt-0 mb-1 fs-14">
                            <router-link
                              to="/"
                              class="text-reset"
                              >{{ product.name }}</router-link
                            >
                          </h6>
                          <p class="mb-0 fs-12 text-muted">
                            Adet:
                            <span
                              >{{ product.quantity }} x
                              {{ $filters.currency(product.price) }}</span
                            >
                          </p>
                        </div>
                        <div class="px-2">
                          <h5 class="m-0 fw-normal">
                            {{
                              $filters.currency(product.subtotal)
                            }}
                          </h5>
                        </div>
                        <div class="ps-2">
                          <button
                            type="button"
                            class="btn btn-icon btn-sm btn-ghost-secondary"
                            @click="removeCartProduct(index)"
                          >
                            <i class="ri-close-fill fs-16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
                <div
                  class="
                    p-3
                    border-bottom-0
                    border-start-0
                    border-end-0
                    border-dashed
                    border
                    d-grid
                  "
                >
                  <div
                    class="d-flex justify-content-between align-items-center pb-3"
                  >
                    <h5 class="m-0 text-muted">{{ $t("Toplam") }}:</h5>
                    <div class="px-2">
                      <h5 class="m-0">
                        {{
                          $filters.currency(
                            $store.getters["cart/getCartTotalPrice"]
                          )
                        }}
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <router-link
                        to="/sepet"
                        class="btn btn-info text-center w-100"
                      >
                        {{ $t("Sepete Git") }}
                      </router-link>
                    </div>
                    <div class="col-6">
                      <router-link
                        to="/teklif-iste"
                        class="btn btn-success text-center w-100"
                      >
                        {{ $t("teklif-iste") }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="ms-1 header-item d-none d-sm-flex">
              <button
                type="button"
                class="
                  btn btn-icon btn-topbar btn-ghost-secondary
                  rounded-circle
                  light-dark-mode
                "
                @click="toggleDarkMode"
              >
                <i class="bx bx-moon fs-22"></i>
              </button>
            </div>
            <div class="dropdown topbar-head-dropdown ms-1 header-item">
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-receipt fs-22"></i>
                <span
                  class="
                    position-absolute
                    topbar-badge
                    fs-10
                    translate-middle
                    badge
                    rounded-pill
                    bg-danger
                  "
                >
                  {{ $store.getters["user/getDraftDocs"]?.length }}<span class="visually-hidden">Taslak</span></span
                >
              </button>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div class="dropdown-head bg-primary bg-pattern rounded-top">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white">
                          Taslak Belgeler "{{ $store.getters['user/getSelectedDraftDoc'] }}"
                        </h6>
                      </div>
                      <div class="col-auto dropdown-tabs">
                        <span class="badge badge-soft-light fs-13">{{ $store.getters["user/getDraftDocs"]?.length }} Taslak</span>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div
                    class="tab-pane fade show active py-2 ps-2"
                    id="all-noti-tab"
                    role="tabpanel"
                  >
                    <SimpleBar
                      data-simplebar
                      style="max-height: 300px"
                      class="pe-2"
                    >
                    <b-list-group>
                      <b-list-group-item class="list-group-item" v-for="dDoc in $store.getters['user/getDraftDocs']" :key="dDoc.docNum" :variant="$store.getters['user/getSelectedDraftDoc'] == dDoc.docNum ?  'secondary' : ''">
                        <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                        <div class="d-flex">
                            <div class="flex-shrink-0 avatar-xs">
                                <div class="avatar-title rounded" :class="{'text-success bg-soft-success' : !dDoc.status, 'text-danger bg-soft-danger' : dDoc.status}">
                                    {{ dDoc.docType }}
                                </div>
                            </div>
                            <div class="flex-grow-1 ms-2">
                                <h6 class="fs-14 mb-0">{{ dDoc.docNum }}</h6>
                                <small class="text-muted"
                                v-b-popover.hover.left="new Date(dDoc.docDate*1000).toLocaleString('tr-TR')"
                                >{{ timeAgo.format(new Date(dDoc.docDate*1000)) }}</small>
                            </div>
                            <div class="px-2 fs-15">
                              <b-button v-b-popover.hover.left="'Belge Ayarları'" variant="outline-secondary" class="btn-icon waves-effect waves-light me-1" size="sm"><i class="ri-edit-fill"></i></b-button>
                              <b-button v-b-popover.hover.right="'Belge Seç'" variant="outline-success" class="btn-icon waves-effect waves-light" size="sm" @click="selectDraftDoc(dDoc.docNum)"><i class="ri-check-fill"></i></b-button>
                            </div>
                        </div>
                        </div>
                        <div class="flex-shrink-0">
                        <span class="text-success"></span>
                        </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
  
                      <div class="my-3 text-center">
                        <button type="button" class="btn btn-soft-success">
                          Tüm Belgeler
                          <i class="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </SimpleBar>
                  </div>
              </div>
            </div>
            <div class="dropdown ms-sm-3 header-item">
              <button
                type="button"
                class="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="d-flex align-items-center">
                  <img
                    class="rounded-circle header-profile-user"
                    src="@/assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span class="text-start ms-xl-2">
                    <span
                      class="
                        d-none d-xl-inline-block
                        ms-1
                        fw-medium
                        user-name-text
                      "
                      >{{ user.mTemsilcisi }}</span
                    >
                    <span
                      class="
                        d-none d-xl-block
                        ms-1
                        fs-12
                        text-muted
                        user-name-sub-text
                      "
                      >{{ $t("Kurumsal Müşteri Temsilcisi") }}</span
                    >
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header">
                  {{ $t("Kurumsal Müşteri Temsilcisi") }}
                </h6>
                <a class="dropdown-item" href="javascript:;"
                  ><i
                    class="
                      mdi mdi-account-supervisor
                      text-muted
                      fs-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle" data-key="t-logout"
                    >Ebru Akbaş</span
                  ></a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="tel:+905000000000"
                  ><i
                    class="mdi mdi-phone text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle" data-key="t-logout"
                    >0 500 000 00 00</span
                  ></a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  href="mailto:ebru.akbas@elektromarketim.com"
                  ><i
                    class="
                      mdi mdi-email-fast-outline
                      text-muted
                      fs-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle" data-key="t-logout"
                    >ebru.akbas@elektromarketim.com</span
                  ></a
                >
              </div>
            </div>
            <div class="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                class="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="d-flex align-items-center">
                  <img
                    class="rounded-circle header-profile-user"
                    src="@/assets/images/users/avatar-3.jpg"
                    alt="Header Avatar"
                  />
                  <span class="text-start ms-xl-2">
                    <span
                      class="
                        d-none d-xl-inline-block
                        ms-1
                        fw-medium
                        user-name-text
                      "
                      >{{ user.name }}</span
                    >
                    <span
                      class="
                        d-none d-xl-block
                        ms-1
                        fs-12
                        text-muted
                        user-name-sub-text
                      "
                      >{{ user.firma }}</span
                    >
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header">
                  {{ $t("Merhaba_name", { name: user.firstName }) }}
                </h6>
                <router-link class="dropdown-item" to="/hesap-bilgileri"
                  ><i
                    class="
                      mdi mdi-account-circle
                      text-muted
                      fs-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle">{{
                    $t("Hesap Bilgileri")
                  }}</span></router-link
                >
                <router-link class="dropdown-item" to="/">
                  <i
                    class="
                      mdi mdi-message-text-outline
                      text-muted
                      fs-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle">{{
                    $t("Mesajlar")
                  }}</span></router-link
                >
                <router-link class="dropdown-item" to="/"
                  ><i
                    class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle">{{
                    $t("Yardım")
                  }}</span></router-link
                >
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/"
                  ><i
                    class="mdi mdi-wallet text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle"
                    >{{ $t("Bakiye") }} : <b>5.971,67 ₺</b></span
                  ></router-link
                >
                <router-link class="dropdown-item" to="/"
                  ><span class="badge bg-soft-success text-success mt-1 float-end"
                    >New</span
                  ><i
                    class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle">Settings</span></router-link
                >
                <router-link class="dropdown-item" to="/"
                  ><i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Lock screen</span></router-link
                >
                <a class="dropdown-item" href="/guvenli-cikis"
                  ><i
                    class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle" data-key="t-logout">Logout</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>